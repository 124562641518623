import * as actions from "../actions/actionTypes";

const initialState = {
  StudentDashBoardData: null,
  ViewAllAssignmentData: null,
  GetAssignmentResponseData: null,
  GetStudentsTrackingCountData: null,
  GetTrackLessonData: null,
  GetTrackLessonResourceData: null,
  GetQuizDetailsData: null,
  GetStartQuizData: null,
  GetSubmitQuizData: null,
  GetStartAssessmentData: null,
  GetSubmitAssessmentData: null,
  GetAssignmentFromLessonUrlData: null,
  GetCheckResourceCompletionData: null,
  GetItemPrintQuizData: null
};
export const StudentdashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_STUDENT_DASHBOARD_SUCCESS:
      return { ...state, StudentDashBoardData: action.payload };
    case actions.GET_VIEW_All_ASSIGNMENTS_SUCCESS:
      return { ...state, ViewAllAssignmentData: action.payload };
    case actions.GET_ASSIGNMENT_RESPONSES_SUCCESS:
      return { ...state, GetAssignmentResponseData: action.payload };
    case actions.GET_OTHER_STUDENTS_TRACKING_SUCCESS:
      return { ...state, GetStudentsTrackingCountData: action.payload };
    case actions.GET_TRACK_LESSON_SUCCESS:
      return { ...state, GetTrackLessonData: action.payload };
    case actions.GET_TRACK_LESSON_RESOURCE_SUCCESS:
      return { ...state, GetTrackLessonResourceData: action.payload };
    case actions.GET_QUIZ_DETAILS_DATA_SUCCESS:
      return { ...state, GetQuizDetailsData: action.payload };
    case actions.GET_START_QUIZ_DATA_SUCCESS:
      return { ...state, GetStartQuizData: action.payload };
    case actions.GET_SUBMIT_QUIZ_DATA_SUCCESS:
      return { ...state, GetSubmitQuizData: action.payload };
    case actions.GET_START_LEARNOSITY_ASSESSMENT_SUCCESS:
      return { ...state, GetStartAssessmentData: action.payload };
    case actions.SUBMIT_LEARNOSITY_ASSESSMENT_SUCCESS:
      return { ...state, GetSubmitAssessmentData: action.payload };
    case actions.GET_ASSIGN_ASSIGNMENT_FROM_LESSONURL_SUCCESS:
      return { ...state, GetAssignmentFromLessonUrlData: action.payload };
    case actions.GET_CHECK_RESOURCE_COMPLETION_SUCCESS:
      return { ...state, GetCheckResourceCompletionData: action.payload };
    case actions.GET_ITEM_PRINT_QUIZ_DATA_SUCCESS:
        return { ...state, GetItemPrintQuizData: action.payload };
    default:
      return state;
  }
};
